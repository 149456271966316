<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Enero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- Titulo del módulo -->
        <div class="d-flex pt-3">
            <v-card-title class="fondoPuntoVenta"><span>{{ subtitulo }}</span></v-card-title>
        </div>

        <!-- Diálogo para editar tiempos de ejecución de una tarea -->
        <v-dialog v-model="dialogo" persistent transition="dialog-bottom-transition" max-width="33.5rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Editar tiempos de ejecución</span>
                </v-card-title>

                <!-- formulario para editar tiempos de ejecución de una tarea -->
                <v-card-text style="padding-top: 0.7rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form class="mt-3" lazy-validation>
                            <!-- Campo para los tiempos de la tarea -->
                            <div class="d-flex">
                                <!-- Campo para el tiempo en horas -->
                                <div class="d-flex align-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, hour: true }">
                                        <v-text-field outlined id="horas" v-model="tarea.horas" :error-messages="errors" 
                                            style="width: 9.8rem" dense label="Horas" @keypress="validarLetra" @paste.prevent
                                            @input="convertirHorasAMinutos">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <!-- Campo para el tiempo en minutos -->
                                <div class="d-flex align-center me-2">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, segund: true }">
                                        <v-text-field outlined id="minutos" v-model="tarea.minutos" :error-messages="errors" 
                                            style="width: 9.8rem" dense label="Minutos" @keypress="validarLetra" @paste.prevent
                                            @input="convertirMinutosAMilisegundos">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                 <!-- Campo para el tiempo en milisegundos -->
                                 <div class="d-flex align-center">
                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, miliSegund: true }">
                                        <v-text-field outlined id="milisegundos" v-model="tarea.milisegundos" :error-messages="errors" 
                                            style="width: 9.9rem" dense label="Milisegundos" @keypress="validarLetra" @paste.prevent
                                            @input="convertirMilisegundosAMinutos">
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                            </div>
                           
                            <!-- Botones de cerrar y guardar que se activan cuando se abre el diálogo para cambiar los tiempos de ejecución -->
                            <div class="d-flex justify-end">
                                <v-btn color="error" text @click="reset()">
                                    CERRAR
                                </v-btn>
                                <v-btn depressed @click="guardarTiempos()" :disabled="cambiandoTiempos || invalid" color="success">
                                    GUARDAR
                                </v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra la lista de las tareas registradas y habilitadas en base de datos -->
        <v-data-table :loading="loading" fixed-header :headers="headersTarea" :items-per-page="99" :items="listaDeTareas"
            class="elevation mt-4" height="66vh" hide-default-footer>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.id">
                        <td class="text-left">{{ item.descripcionTarea }}</td>
                        <td class="text-left">{{ item.tiempoMinutos }}</td>
                        <td class="text-left">{{ item.tiempoMilisegundos }}</td>
                        <td>
                            <v-icon :color="item.estado === true ? 'success' : 'error'"> 
                                {{ item.estado === true ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.estado === true ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <!-- Campo que permite abrir un diálogo para cambiar el estado de una tarea -->
                            <v-tooltip bottom :color="ejecucionEnProceso === false ? (item.estado === true ? '#ff5252' : 'success') : '#686868'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="me-2" icon @click="ejecucionEnProceso === false ? abrirDialogoEstado(item) : null" v-bind="attrs" v-on="on">
                                        <v-icon :color="ejecucionEnProceso === false ? (item.estado === true ? '#ff5252' : 'success') : '#686868'">
                                            {{ item.estado === true ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ ejecucionEnProceso === false ? (item.estado === true ? 'Inactivar' : 'Activar') : 'Ejecución en proceso' }}</span>
                            </v-tooltip>
                            <!-- Campo que permite abrir un diálogo para editar los tiempos de ejecución de una tarea -->
                            <v-tooltip bottom :color="(item.estado !== true || ejecucionEnProceso) ? '#686868' : 'orange'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button class="me-2" icon @mousedown.prevent="(item.estado === true && !ejecucionEnProceso) ? abrirDialogoEditar(item) : null" v-bind="attrs" v-on="on">
                                        <v-icon :color="(item.estado !== true || ejecucionEnProceso) ? '#686868' : 'orange'">{{ 'border_color' }}</v-icon>
                                    </button>
                                </template>
                                <span v-if="item.estado !== true">Active primero la tarea</span>
                                <span v-else-if="ejecucionEnProceso">Ejecución en proceso</span>
                                <span v-else>Editar</span>
                            </v-tooltip>
                            <!-- Campo que permite abrir un diálogo para correr una tarea instantaneamente -->
                            <v-tooltip bottom :color="(item.estado !== true || ejecucionEnProceso) ? '#686868' : 'success'">
                                <template v-slot:activator="{ on, attrs }">
                                    <button icon @mousedown.prevent="(item.estado === true && ejecucionEnProceso !== true) ? abrirDialogoEjecutar(item) : null" v-bind="attrs" v-on="on">
                                        <v-icon :color="(item.estado !== true || (ejecucionEnProceso && tareaSelecionada.idTarea !== item.idTarea)) ? '#686868' : 'success'" :class="item.ejecutando ? 'rotate-animation' : ''">
                                            {{ item.ejecutando ? 'rotate_right' : 'play_arrow' }}
                                        </v-icon>
                                    </button>
                                </template>
                                <span v-if="item.estado !== true">Active primero la tarea</span>
                                <span v-else-if="item.ejecutando === true || ejecucionEnProceso">Ejecución en proceso</span>
                                <span v-else>Ejecutar</span>
                            </v-tooltip>
                            <!-- Campo que permite abrir un diálogo para eliminar una tarea -->
                            <v-tooltip bottom :color="(item.eliminado === true || ejecucionEnProceso) ? '#686868' : '#ff5252'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="me-2" icon @click="ejecucionEnProceso === false ? abrirDialogoEliminar(item) : null" v-bind="attrs" v-on="on">
                                        <v-icon :color="(item.eliminado === true || ejecucionEnProceso) ? '#686868' : 'error'">
                                            {{ item.eliminado === false ? 'delete_forever' : 'delete' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ (item.eliminado === true || ejecucionEnProceso) ? 'Ejecución en proceso' : 'Eliminar' }}</span>
                            </v-tooltip>
                        </td>
                        <!-- Campo que permite abrir un diálogo para ver las notificaciones de errores de la tarea -->
                        <td class="text-center">
                            <v-tooltip bottom :color="ejecucionEnProceso ? '#686868' : '#2b6fd4'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="ejecucionEnProceso === false ? abrirDialogoNotificacion(item) : null" icon v-bind="attrs" v-on="on">
                                        <v-icon medium :color="ejecucionEnProceso ? '#686868' : '#2b6fd4'">{{ tieneNotificacionesNoVistas(item) ? 'mark_email_unread' : 'drafts' }}</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ ejecucionEnProceso ? 'Ejecución en proceso' : 'Notificación' }}</span>
                            </v-tooltip>
                            <v-tooltip bottom :color="ejecucionEnProceso ? '#686868' : 'info'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="ejecucionEnProceso === false ? verUltimaFecha(item) : null" icon v-bind="attrs" v-on="on">
                                        <v-icon medium :color="ejecucionEnProceso ? '#686868' : 'info'">work_history</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ ejecucionEnProceso ? 'Ejecución en proceso' : 'Última ejecución' }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>

        <!-- Diálogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed :disabled="enProceso" @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <!-- Diálogo para ejecutar la tarea instantaneamente -->
        <v-dialog v-model="dialogoEjecutar" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">¿Está seguro de ejecutar la tarea?</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoEjecutar = false">No</v-btn>
                        <v-btn color="success" depressed @click="ejecutar()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Diálogo para eliminar la tarea -->
        <v-dialog v-model="dialogoEliminar" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">¿Está seguro de eliminar la tarea?</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoEliminar = false">No</v-btn>
                        <v-btn color="success" depressed :disabled="eliminando" @click="eliminar()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Diálogo para ver las notificaciones de errores de cada tarea -->
        <v-dialog v-model="dialogoNotificacion" transition="dialog-bottom-transition" max-width="50rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Notificación de la tarea {{ nombreTarea }}</span>
                </v-card-title>

                <v-card-text>
                    <!-- Tabla que muestra la lista de las notificaciones de error de la tarea -->
                    <v-data-table fixed-header :headers="headersNotificacion" :items="listaDeNotificaciones"
                        :page.sync="paginaNotificacion" :items-per-page.sync="itemsPorPaginaNotificacion" height="22rem"
                        class="elevation notificacionError mt-4" :sort-by="'time'" :sort-desc="true" :footer-props="footerPropsNotificacion">
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.id">
                                    <td class="text-left">{{ item.errorGenerado }}</td>
                                    <td class="text-left">{{ formatearFecha(item.fechaCreacion) }}</td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:footer.page-text="items">
                            {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                        </template>
                    </v-data-table>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="cerrarDialogoNotificacion(listaDeNotificaciones)">CERRAR</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Contenedor para mostrar los mensajes de error -->
        <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
            <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
            <span>{{ mensajeNotificacion }}</span>
        </div>

        <!-- Mensaje de alerta para cuando hay una ejecución manual en proceso en proceso y cuando termina-->
        <template>
            <div v-if="executionInProcess || finishedExecution" class="overlay">
                <v-alert class="executionValidation" persistent transition="dialog-bottom-transition">
                    <v-icon class="d-flex justify-center" :class="executionInProcess ? 'rotate-animation' : null" color="white" large>{{ finishedExecution ? 'task_alt' : 'rotate_right' }}</v-icon>
                    <span class="d-flex justify-center">{{ executionInProcess ? 'Ejecutando tasks, por favor espere.' : 'Ejecución finalizada.' }}</span>
                </v-alert>
            </div>
        </template>

        <!-- dialogo para ver la ultima fecha de ejecución de la tarea -->
        <v-dialog class="home" v-model="dialogLastDate" persistent transition="dialog-bottom-transition" max-width="28rem">
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">Última fecha de ejecución</span>
                </v-card-title>

                <v-card-text style="padding-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Inicio de ejecución: </span>
                                <span>{{ formatearFecha(fechaInicial) }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12" align="left" class="ma-0 pa-0 letra">
                                <span class="font-weight-black">Final de ejecución: </span>
                                <span>{{ formatearFecha(fechaFinal) }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div class="d-flex justify-end">
                        <v-btn color="error" text @click="closeDialogLastDate()"> CERRAR </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider,  } from 'vee-validate';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import { required } from 'vee-validate/dist/rules';
import moment from 'moment';

extend('required', {
    ...required,
    message: 'Este campo es requerido'
})
extend('hour', {
  validate: value => {
    if (value < 1) {
      return false;
    }
    if (!/\d*[1-9]\d*/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'Mínimo 1 h.'
})
extend('segund', {
    validate: value => {
        if (value < 60) {
            return false;
        }
        return true;
    },
    message: 'Mínimo 60 m.'
})
extend('miliSegund', {
    validate: value => {
        if (value < 3600000) {
            return false;
        }
        return true;
    },
    message: 'Mínimo 3600000 ms.'
})

export default {
    name: 'Tasks',
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            subtitulo: 'TAREAS PROGRAMADAS',
            usuario: '',
            dialogo: false,
            tarea: {
                milisegundos: '',
                minutos: '',
                horas: '',
            },
            eliminado: false,
            loading: false,
            headersTarea: [
                { text: "DESCRIPCIÓN", align: "left", sortable: false, width: "10rem" },
                { text: "TIEMPO MINUTOS", align: "left", sortable: false, width: "10rem" },
                { text: "TIEMPO MILISEGUNDOS", align: "left", sortable: false, width: "10rem" },
                { text: "ESTADO", sortable: false, width: "7rem" },
                { text: "ACCIONES", align: "center", sortable: false, width: "10rem" },
                { text: "INFORMACIÓN", align: "center", sortable: false, width: "1rem" },
            ],
            listaDeTareas: [],
            tareaSelecionada: {},
            dialogoCambiarEstado: false,
            dialogoEjecutar: false,
            dialogoEliminar: false,
            dialogoNotificacion: false,
            mensajeEstado: '',
            ejecutando: false,
            ejecucionEnProceso: false,
            mostrarNotificacion: false,
            mensajeNotificacion: "",
            tipoNotificacion: "",
            tipoNotificacionColor: "",
            iconoNotificacion: "",
            iconoNotificacionClase: "",
            nombreTarea: "",
            headersNotificacion: [
                { text: "DESCRIPCIÓN", align: "left", sortable: false, width: "18rem" },
                { text: "FECHA", align: "left", sortable: false, width: "7rem" },
            ],
            listaDeNotificaciones: [],
            paginaNotificacion: 1,
            itemsPorPaginaNotificacion: 5,
            footerPropsNotificacion: {
                'show-current-page': true,
                'show-first-last-page': true,
            },
            executionInProcess: false,
            finishedExecution: false,
            dialogLastDate: false,
            fechaInicial: null,
            fechaFinal: null,
            enProceso: false,
            eliminando: false,
            cambiandoTiempos: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.usuario = this.auth.username.toUpperCase();
        this.userRoles = this.auth.roles;
        this.listarTareas();
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },

    watch: {
        // Establece 'paginaNotificacion' en 1 cuando la variable 'listaDeNotificaciones' cambia
        'listaDeNotificaciones': function () {
            this.paginaNotificacion = 1;
        },
        'dialogoCambiarEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.enProceso = false;
                }, 500)
            }
        },
        'dialogoEliminar': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.eliminando = false;
                }, 500)
            }
        },
        'dialogo': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.cambiandoTiempos = false;
                }, 500)
            }
        },
    },

    mounted() {
        this.usuario = this.auth.username.toUpperCase();
    },

    methods: {
        ...mapMutations(["updateAuth", "hideNotify", "showSuccess", "showBusy", "hideBusy",]),

        /**
         * Valida que solo se ingresen caracteres númericos en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea número, evita que se registre en el campo.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9.]/)) {
                event.preventDefault();
            }
        },

        /**
         * Ajusta el número de decimales de acuerdo a la cantidad de decimales presentes en el número.
         * 
         * @param {*} numero - Número al que se le ajustarán los decimales.
         * @returns {number|string} - Número con decimales ajustados.
         */
        ajustarDecimales(numero) {
            const decimales = (numero.toString().split('.')[1] || '').length;
            return decimales > 0 ? parseFloat(numero).toFixed(2) : numero;
        },

        /**
         * Convierte la cantidad de minutos ingresada en milisegundos y actualiza las propiedades correspondientes.
         */
        convertirMinutosAMilisegundos() {
            if (this.tarea.minutos !== "") {
                this.tarea.milisegundos = this.tarea.minutos * 60000; // 1 minuto = 60000 milisegundos
                this.tarea.horas = (this.tarea.minutos / 60).toFixed(2);
            } else {
                this.tarea.milisegundos = "";
                this.tarea.horas = "";
            }
        },

        /**
         * Convierte la cantidad de milisegundos ingresada en minutos y actualiza las propiedades correspondientes.
         */
        convertirMilisegundosAMinutos() {
            if (this.tarea.milisegundos !== "") {
                this.tarea.minutos = this.ajustarDecimales(this.tarea.milisegundos / 60000); // 1 minuto = 60000 milisegundos
                this.tarea.horas = this.ajustarDecimales(this.tarea.minutos / 60);
            } else {
                this.tarea.minutos = "";
                this.tarea.horas = "";
            }
        },

        /**
         * Convierte la cantidad de horas ingresada en minutos y milisegundos, y actualiza las propiedades correspondientes.
         */
        convertirHorasAMinutos() {
            if (this.tarea.horas !== "") {
                this.tarea.minutos = this.tarea.horas * 60; // 1 hora = 60 minutos
                this.tarea.milisegundos = this.tarea.minutos * 60000; // 1 minuto = 60000 milisegundos
            } else {
                this.tarea.minutos = "";
                this.tarea.milisegundos = "";
            }
        },

        /**
         * Obtiene la lista de tareas habilitadas mediante una solicitud HTTP GET al servidor.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarTareas() {
            this.loading = true;
            this.$http.get(`msa-tasks/parametrizacionTareas/listar`, {
                params: {
                    idEmpresa: this.enterprise.code
                }
            })
            .then( response => {
                this.listaDeTareas = response.data;
                this.loading = false;
            }).catch(error => {
                console.log(error)
                this.loading = false;
                this.manejarError(error);
            });
        },

        /**
         * Muestra una notificación global en el componente.
         *
         * @param {string} mensaje - Mensaje que se mostrará en la notificación.
         * @param {string} tipo - Tipo de la notificación (por defecto, "error").
         * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
         */
        mostrarNotificacionGlobal(mensaje, tipo, icono) {
            this.mostrarNotificacion = true;
            this.tipoNotificacion = tipo || "error"; // Tipo predeterminado es "error"
            this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
            this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
            this.mensajeNotificacion = mensaje;
            this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000); 
        },

        /**
         * Cierra la notificación
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.tipoNotificacion = "";
        },

        /**
         * Obtiene el color correspondiente al tipo de notificación.
         * 
         * @param {string} tipo - Tipo de notificación.
         * @returns {string} - Color de la notificación.
         */
        obtenerColorNotificacion(tipo) {
            switch (tipo) {
                case "advertencia":
                    return "#f80505";
                default:
                    return "#f80505"; 
            }
        },

        /**
         * Obtiene la clase de icono correspondiente al tipo de notificación.
         * 
         * @param {*} tipo  - Tipo de notificación.
         * @returns {string} - Clase de icono.
         */
        obtenerClaseIcono(tipo) {
            switch (tipo) {
                case "advertencia":
                    return "advertencia-icon";
                default:
                    return "error-icon";
            }
        },

        /**
         * Maneja errores y muestra notificaciones correspondientes.
         * 
         * @param {*} error - Objeto de error.
         */
        manejarError(error) {
            this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "error", "highlight_off");

            if (error.response) {
                if (error.response.status === 503 || error.response.status === 500) {
                    this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
                } else {
                    this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "error", "highlight_off");
                }
            }
        },

        /**
         * Abre el diálogo para permitir ejecutar la tarea seleccionada.
         * Establece la propiedad 'dialogo' en true para mostrar el diálogo.
         * Guarda la tarea seleccionada en la propiedad 'tareaSelecionada'.
         * Este método se invoca cuando el usuario desea cambiar los tiempos de ejecución de la tarea.
         * @param {object} item - la tarea seleccionada.
         */
        abrirDialogoEditar(item) {
            this.dialogo = true
            this.tareaSelecionada = item;
            this.tarea.milisegundos = item.tiempoMilisegundos;
            this.tarea.minutos = item.tiempoMinutos;
            this.tarea.horas = this.tarea.minutos / 60;
        },

        /**
         * Guarda la información de los nuevos tiempos de una tarea en el servidor.
         * Crea un objeto con los datos de los tiempos de la tarea y realiza una solicitud al servidor para registrar la tarea. 
         * Luego, actualiza la lista de las tarea y restablece el formulario.
         */
        guardarTiempos() {
            this.cambiandoTiempos = true;
            const datos = {
                idTarea: this.tareaSelecionada.idTarea,
                tiempoMilisegundos: Number(this.tarea.milisegundos),
                tiempoMinutos: Number(this.tarea.minutos),
                estado: this.tareaSelecionada.estado,
                eliminado: this.tareaSelecionada.eliminado,
                lastmodifiedby: this.usuario.toLowerCase(),
                empresa: {
                    idEmpresa: this.enterprise.code
                }
            }
            this.$http.put("msa-tasks/parametrizacionTareas/actualizar", datos)
            .then(() => {
                this.listarTareas();
                this.reset();
            })
            .catch((error) => {
                console.log(error);
                this.manejarError(error);
            })
        },

        /**
         * Abre el diálogo para cambiar el estado de una tarea.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda la tarea seleccionada en la propiedad 'tareaSelecionada'.
         * Este método se invoca cuando se desea cambiar el estado de una tarea.
         * @param {object} item - la tarea seleccionada.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true
            this.tareaSelecionada = item;
            this.mensajeEstado = this.tareaSelecionada.estado === true ? '¿Desea inactivar la tarea?' : '¿Desea activar la tarea?';
        },

        /**
         * Cambia el estado de una tarea.
         * Actualiza el estado de la tarea seleccionada ('tareaSelecionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado de la tarea.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de tareas.
         */
        cambiarEstado() {
            this.enProceso = true;
            this.tareaSelecionada.estado = (this.tareaSelecionada.estado == false) ? true : false;
            const cambiarEstado = {
                idTarea: this.tareaSelecionada.idTarea,
                tiempoMilisegundos: this.tareaSelecionada.tiempoMilisegundos,
                tiempoMinutos: this.tareaSelecionada.tiempoMinutos,
                estado: this.tareaSelecionada.estado,
                eliminado: this.tareaSelecionada.eliminado,
                lastmodifiedby: this.usuario.toLowerCase(),
                empresa: {
                    idEmpresa: this.enterprise.code
                }
            }
            this.$http
                .put(`msa-tasks/parametrizacionTareas/actualizar`, cambiarEstado)
                .then(() => {
                    this.listarTareas();
                    this.dialogoCambiarEstado = false;
                    this.tareaSelecionada = {};
                }).catch(error => {
                    this.manejarError(error);
                });
        },

        /**
         * Abre el diálogo para permitir ejecutar la tarea seleccionada.
         * Establece la propiedad 'dialogoEjecutar' en true para mostrar el diálogo.
         * Guarda la tarea seleccionada en la propiedad 'tareaSelecionada'.
         * Este método se invoca cuando el usuario desea ejecutar la tarea instantaneamente.
         * @param {object} item - la tarea seleccionada.
         */
        abrirDialogoEjecutar(item) {
            this.dialogoEjecutar = true
            this.tareaSelecionada = item;
        },

        /**
         * Envia una petición al back para ejecutar tarea seleccionada evadiendo los tiempos programados.
         * Actualiza la relación con la ('tareaSelecionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para la ejecución.
         * Después de la ejecución exitosa, se registra el mensaje y se actualiza la lista de tareas.
         */
        ejecutar() {
            this.executionInProcess = true;
            const idTarea = this.tareaSelecionada.idTarea;
            this.dialogoEjecutar = false;
            this.$set(this.tareaSelecionada, 'ejecutando', true);
            this.ejecucionEnProceso = true;
            setTimeout(() => {
                this.executionInProcess = false;
                this.$http
                .get(`msa-tasks/parametrizacionTareas/ejecutar?idTarea=${idTarea}&idEmpresa=${this.enterprise.code}`)
                .then(() => {
                    this.$set(this.tareaSelecionada, 'ejecutando', false);
                    this.ejecucionEnProceso = false;
                    this.tareaSelecionada = {};
                    this.finishedExecution = true;
                    setTimeout(() => {
                        this.finishedExecution = false;
                        this.listarTareas();
                    }, 3000);
                }).catch(error => {
                    this.manejarError(error);
                    this.executionInProcess = false;
                    this.$set(this.tareaSelecionada, 'ejecutando', false);
                });
            }, 2000);
        },

        /**
         * Abre el diálogo para eliminar una tarea seleccionada.
         * Establece la propiedad 'dialogoEliminar' en true para mostrar el diálogo.
         * Guarda la tarea seleccionada en la propiedad 'tareaSelecionada'.
         * Este método se invoca cuando el usuario desea eliminar todas las funcionalidades de la tarea.
         * @param {object} item - la tarea seleccionada.
         */
        abrirDialogoEliminar(item) {
            this.dialogoEliminar = true;
            this.tareaSelecionada = item;
        },

        /**
         * Cambia el eliminado y el estado de una tarea seleccionada.
         * Realiza una solicitud HTTP PUT al servidor para cambiar la tarea.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de tareas.
         */
        eliminar() {
            this.eliminando = true;
            this.tareaSelecionada.estado = false;
            this.tareaSelecionada.eliminado = true;
            const eliminar = {
                idTarea: this.tareaSelecionada.idTarea,
                tiempoMilisegundos: this.tareaSelecionada.tiempoMilisegundos,
                tiempoMinutos: this.tareaSelecionada.tiempoMinutos,
                estado: this.tareaSelecionada.estado,
                eliminado: this.tareaSelecionada.eliminado,
                lastmodifiedby: this.usuario.toLowerCase(),
                empresa: {
                    idEmpresa: this.enterprise.code
                }
            }
            this.$http
                .put(`msa-tasks/parametrizacionTareas/actualizar`, eliminar)
                .then(() => {
                    this.listarTareas();
                    this.dialogoEliminar = false;
                    this.tareaSelecionada = {};
                }).catch(error => {
                    this.manejarError(error);
                });
        },
        
        /**
         * Verifica si el objeto 'item' tiene notificaciones no vistas en sus tareas.
         * 
         * @param {*} item - El objeto que se va a verificar.
         * @returns {boolean} - True si hay notificaciones no vistas, false en caso contrario.
         */
        tieneNotificacionesNoVistas(item) {
            return item.logTareas.some(tarea => tarea.vista === false);
        },

        /**
         * Abre el diálogo de notificaciones, estableciendo las variables necesarias
         * con la información proporcionada por el objeto 'item'.
         * 
         * @param {*} item - El objeto que contiene la información de la tarea.
         */
        abrirDialogoNotificacion(item) {
            this.dialogoNotificacion = true;
            this.tareaSelecionada = item;
            this.nombreTarea = item.descripcionTarea;
            this.listaDeNotificaciones = item.logTareas;
        },

        /**
         * Cierra el diálogo de notificaciones y envía al backend solo los registros
         * donde item.vista sea false, cambiando su valor a true antes de enviarlos.
         * Si no hay registros pendientes, no realiza la petición y actualiza las variables locales.
         * 
         * @param {Array} listaDeNotificaciones - La lista de notificaciones a procesar.
         */
        cerrarDialogoNotificacion(listaDeNotificaciones) { 
            const notificacionesPendientes = listaDeNotificaciones.filter(item => !item.vista);

            if (notificacionesPendientes.length > 0) {
                const notificaciones = notificacionesPendientes.map(item => ({ idLota: item.idLota, vista: true }));

                this.$http.put(`msa-tasks/logTareas/actualizar`, notificaciones)
                .then(() => {
                    this.listarTareas();
                    this.dialogoNotificacion = false;
                    this.listaDeNotificaciones = [];
                }).catch(error => {
                    console.log(error);
                    this.manejarError(error);
                });
            } else {
                this.dialogoNotificacion = false;
                this.listaDeNotificaciones = [];
            }
        },

        /**
         * Restablece los valores de los campos y las variables relacionadas a la tarea.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialogo = false;
            this.tarea.milisegundos = '';
            this.tarea.minutos = '';
            this.tarea.horas = '';
            this.tareaSelecionada = {};
            this.$refs.observer.reset();
        },

        /**
         * Formatea la fecha en el formato 'DD-MM-YYYY h:mm a'.
         * 
         * @param {Date} fecha - La fecha a formatear.
         * @returns {string} La fecha formateada.
         */
        formatearFecha(fecha) {
            return moment(fecha).format('DD-MM-YYYY h:mm a');
        },

        /**
         * Muestra la fecha de inicio y finalización de la ultima fecha de ejecución de la tarea rpogramada.
         * @param {*} item - tarea programada sobre la cual se ejecuta consulta.
         */
        verUltimaFecha(item) {
            this.fechaInicial = item.inicioEjecucion;
            this.fechaFinal = item.finalEjecucion;
            this.dialogLastDate = true;
        },

        /** Cierra el dialogo de la última fecha de ejecución del task */
        closeDialogLastDate() {
            this.dialogLastDate = false;
        }
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoPuntoVenta {
  background-color: #2b6fd4;
  color: white;
  width: 100%;
  padding: 0% !important;
  border-radius: 0.30rem;
}
.fondoPuntoVenta span{
    font-size: medium;
    width: 100%;
    text-align: center;
}
.fondoDialog {
  background-color: #1867c0;
  color: white;
}
.formulario-flex {
  flex-wrap: nowrap;
}
@media (max-width: 700px) {
  .formulario-flex {
    flex-wrap: wrap !important;
  }
}
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
.rotate-animation{
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.error {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
}
.error-icon {
  color: #f80505;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.executionValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30vw;
}
.letra {
    color: black;
    font-size: medium;
}
</style>